<template>
    <section class="bg-white dark:bg-gray-900 pb-8">
        <div class="container px-6 py-12 mx-auto">
            <h1 class="text-2xl font-semibold text-gray-800 lg:text-3xl dark:text-white mx-4 md:mx-16">
                Sıkça Sorulan Sorular.
            </h1>

            <div class="grid grid-cols-1 gap-8 mt-8 lg:mt-16 md:grid-cols-2 xl:grid-cols-3 mx-4 md:mx-16">
                <div>
                    <div class="inline-block p-3 text-white bg-blue-600 rounded-lg">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>

                    <div>
                        <h1 class="text-xl font-semibold text-gray-700 dark:text-white">
                            Hizmetleriniz nelerdir?
                        </h1>

                        <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                            Şirketimiz, konut ve ticari alanlarda ısıtma, soğutma, havalandırma (HVAC) sistemlerinin kurulumu, bakımı ve onarımını gerçekleştirmektedir. Ayrıca enerji verimliliği danışmanlığı hizmeti de sunmaktayız.
                        </p>
                    </div>
                </div>

                <div>
                    <div class="inline-block p-3 text-white bg-blue-600 rounded-lg">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>

                    <div>
                        <h1 class="text-xl font-semibold text-gray-700 dark:text-white">
                            Sistemimin bakımını ne sıklıkla yaptırmalıyım?
                        </h1>

                        <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                            Genel olarak, HVAC sisteminizin yılda iki kez (ilkbahar ve sonbahar) bakımdan geçirilmesini öneririz. Bu, sistemin verimliliğini korur ve potansiyel sorunları erkenden tespit etmenizi sağlar.
                        </p>
                    </div>
                </div>

                <div>
                    <div class="inline-block p-3 text-white bg-blue-600 rounded-lg">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>

                    <div>
                        <h1 class="text-xl font-semibold text-gray-700 dark:text-white">
                            Enerji faturalarımı nasıl düşürebilirim?
                        </h1>

                        <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                            Enerji faturalarınızı düşürmek için birkaç yöntem bulunmaktadır: düzenli bakım yaptırmak, programlanabilir termostat kullanmak, yalıtımı iyileştirmek ve enerji verimli cihazlara yatırım yapmak. Detaylı bir enerji değerlendirmesi için bizimle iletişime geçebilirsiniz.
                        </p>
                    </div>
                </div>

                <div>
                    <div class="inline-block p-3 text-white bg-blue-600 rounded-lg">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>

                    <div>
                        <h1 class="text-xl font-semibold text-gray-700 dark:text-white">
                            Isı pompası mı, geleneksel klima mı tercih etmeliyim?
                        </h1>

                        <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                            Bu, yaşadığınız bölgenin iklimine, evinizin özelliklerine ve bütçenize bağlıdır. Isı pompaları hem ısıtma hem de soğutma yapabilirken, geleneksel klimalar sadece soğutma yapar. Size en uygun seçeneği belirlemek için ücretsiz keşif hizmetimizden yararlanabilirsiniz.
                        </p>
                    </div>
                </div>

                <div>
                    <div class="inline-block p-3 text-white bg-blue-600 rounded-lg">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>

                    <div>
                        <h1 class="text-xl font-semibold text-gray-700 dark:text-white">
                            Yeni bir sistem kurulumu ne kadar sürer?
                        </h1>

                        <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                            Kurulum süresi, sistemin türüne ve evinizin/işyerinizin büyüklüğüne göre değişir. Basit bir konut klima sistemi genellikle 1-2 gün içinde kurulabilirken, daha karmaşık ticari HVAC sistemleri birkaç hafta sürebilir. Kesin bir süre için sizinle bir ön değerlendirme yapmamız gerekir.
                        </p>
                    </div>
                </div>

                <div>
                    <div class="inline-block p-3 text-white bg-blue-600 rounded-lg">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>

                    <div>
                        <h1 class="text-xl font-semibold text-gray-700 dark:text-white">
                            Garanti ve servis planlarınız nelerdir?
                        </h1>

                        <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                            Tüm yeni sistemlerimiz için standart 5 yıllık bir garanti sunuyoruz. Ayrıca, düzenli bakım ve öncelikli servis içeren genişletilmiş garanti planlarımız da mevcuttur. Bu planlar hakkında daha fazla bilgi için müşteri hizmetlerimizle iletişime geçebilirsiniz.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "FAQ",
    };
</script>