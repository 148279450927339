<template>
    <section class="text-gray-400 bg-white dark:bg-gray-900 body-font relative">
  <div class="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
    <div class="lg:w-2/3 md:w-1/2 bg-white dark:bg-gray-900 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
    <iframe width="100%" height="100%" title="map" class="absolute inset-0" frameborder="0" marginheight="0" marginwidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3009.87415863356!2d28.79529307551496!3d41.02800901821055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa443af8d13ef%3A0xc4c44928a9dded9c!2zxLBuw7Zuw7wsIFNlbGltIFNrLiBObzoyNiwgMzQyOTUgS8O8w6fDvGvDp2VrbWVjZS_EsHN0YW5idWw!5e0!3m2!1str!2str!4v1726862794467!5m2!1str!2str" style="filter:  contrast(1.0) opacity(0.9);"></iframe>
      <div class="bg-white dark:bg-gray-900 relative flex flex-wrap py-6 rounded shadow-md">
        <div class="lg:w-1/2 px-6">
          <h2 class="title-font font-semibold text-gray-600 dark:text-white tracking-widest text-xs">ADRES</h2>
          <p class="mt-1">İnönü, Selim Sk. No:26, 34295 Küçükçekmece/İstanbul</p>
        </div>
        <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
          <h2 class="title-font font-semibold text-gray-600 dark:text-white tracking-widest text-xs">EMAIL</h2>
          <a class="text-indigo-400 leading-relaxed">Example@AsafIsıSogutma.com</a>
          <h2 class="title-font font-semibold text-gray-600 dark:text-white tracking-widest text-xs mt-4">TELEFON</h2>
          <p class="leading-relaxed">+90 (538) 861 69 52</p>
        </div>
      </div>
    </div>
    <div class="lg:w-1/3 md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
      <h2 class="text-gray-600 dark:text-white text-lg mb-1 font-medium title-font">Size Ulaşalım</h2>
      <p class="leading-relaxed mb-5">En kısa sürede sizinle iletişime geçeceğiz</p>
      <div class="relative mb-4">
        <label for="name" class="leading-7 text-sm text-gray-600 dark:text-gray-400">İsim Soyisim</label>
        <input type="text" id="name" name="name" v-model="name" class="w-full dark:bg-gray-800 rounded border dark:border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-600 dark:text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
      </div>
      <div class="relative mb-4">
        <label for="email" class="leading-7 text-sm text-gray-600 dark:text-gray-400">Email</label>
        <input type="email" id="email" name="email" v-model="email" class="w-full dark:bg-gray-800 rounded border dark:border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-600 dark:text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
      </div>
      <div class="relative mb-4">
        <label for="phone" class="leading-7 text-sm text-gray-600 dark:text-gray-400">Telefon</label>
        <input type="phone" id="phone" name="phone" v-model="phone" class="w-full dark:bg-gray-800 rounded border dark:border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-600 dark:text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
      </div>
      <div class="relative mb-4">
        <label for="message" class="leading-7 text-sm text-gray-600 dark:text-gray-400">Mesaj</label>
        <textarea id="message" name="message" v-model="message" class="w-full dark:bg-gray-800 rounded border dark:border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 h-32 text-base outline-none text-gray-600 dark:text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
      </div>
      <button @click="submitForm" class="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Gönder</button>
      <!--<p class="text-xs text-gray-400 text-opacity-90 mt-3">Chicharrones blog helvetica normcore iceland tousled brook viral artisan.</p>-->
    </div>
  </div>
</section>
</template>

<script>
  import axios from 'axios';
  export default {
      name: 'MapSection',
      data() {
        return {
          name: '',
          email: '',
          phone: '',
          message: ''
        };
      },
      methods: {
        async submitForm() {
          console.log('name:', this.name);
          try {
            const response = await axios.post('https://api.ozbagotomasyon.com.tr/newuser', {
              username: this.name,
              email: this.email,
              phone: this.phone,
              message: this.message
            }, {
              headers: {
                'Content-Type': 'application/json'
              }
            });
            console.log(response.data);
            // Reset form fields
            this.name = '';
            this.email = '';
            this.phone = '';
            this.message = '';
          } catch (error) {
            console.error(error);
          }
        }
        }
      }
  
    
</script>