<template>
  <TopBar></TopBar>
  <MapSection></MapSection>
  <MainWorkAreas></MainWorkAreas>
  <FAQ></FAQ>
  <PageFooter></PageFooter>
</template>

<script>
import TopBar from '../../components/TopBar.vue'
import MapSection from './homepagecomponents/MapSection.vue'
import MainWorkAreas from './homepagecomponents/MainWorkAreas.vue'
import FAQ from './homepagecomponents/FAQ.vue'
import PageFooter from '../../components/Footer.vue'
export default {
  name: 'App',
  components: {
    TopBar,
    MapSection,
    MainWorkAreas,
    FAQ,
    PageFooter
  }
}
</script>

<style>

</style>
